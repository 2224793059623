/* 
  We've already included normalize.css. 

  But we'd like a modern looking boilerplate. 
  Clean type, sans-serif, and a nice color palette. 
  
*/

body {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #cccccc;
  background-color: #131315;
  box-sizing: content-box;
}

#app {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

pre {
  padding: 1rem;
  font-family: monospace;
  max-width: 100%;
  margin: 0;
  max-height: 100vh;
  border: 1px solid #383838;
  display: flex;
  align-items: center;
  justify-self: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  outline: 1px solid #383838;
  transition: all 0.2s ease-in-out
}

pre:hover {
  outline: 2px solid #6cefce;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  background-color: #1e1e1e;
}
